export const environment = 
{
    production: false,
    apiUrl: 'https://api-ndg.testing.kainet.dev/api/v1',
    loginEndPoint: '/auth:google',
    logoutEndPoint: '/auth:logout',
    socialiteApiKey: '699028095262-cgq2aa8l4d8ueqha6jugdpjmfh704bh3.apps.googleusercontent.com',
    googleApiKey: '',
    erpUrl: 'http://test.oprcer.com.ar/',
    erpV2Url: 'https://www.ngranos.com.ar/ssgWebBZTest/'
}